import PropTypes from 'prop-types';
import React from 'react';

import NavigationListItem from 'components/shared/NavigationListItem';

export default class Navigation extends React.Component {
	render() {
		const navigationList = this.props.navigationList.map((item) => (
			<NavigationListItem
				className={`${this.props.itemClassName} ${item.className}`}
				text={item.text}
				url={item.url}
			/>
		));

		return (
			<nav className={this.props.containerClassName}>
				<ul className={this.props.className}>{navigationList}</ul>
			</nav>
		);
	}
}

Navigation.propTypes = {
	className: PropTypes.string,
	containerClassName: PropTypes.string,
	itemClassName: PropTypes.string,
	navigationList: PropTypes.arrayOf(
		PropTypes.shape({
			className: PropTypes.string,
			url: PropTypes.string,
			text: PropTypes.string,
		})
	).isRequired,
};
