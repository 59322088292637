import React from 'react';

import content from '../content/content.json';
import Navigation from 'components/shared/Navigation';

export default class AppFooter extends React.Component {
	render() {
		const navigationData = [
			{
				className:
					'hover:underline mr-4 transition-decoration text-black',
				text: content.footer.navigation.privacy,
				url: '/privacy-policy',
			},
			{
				className: 'hover:underline transition-decoration text-black',
				text: content.footer.navigation.terms,
				url: '/terms-of-use',
			},
		];

		return (
			<footer>
				<div className="flex flex-col lg:flex-row lg:justify-between max-w-3xl lg:max-w-7xl mx-auto px-8 py-6">
					<Navigation
						className="flex flex-row justify-between mb-8 lg:mb-0"
						navigationList={navigationData}
					/>
					<p className="font-normal text-color-text-primary">
						&copy; {new Date().getFullYear()}{' '}
						{content.footer.copyright}
					</p>
				</div>
			</footer>
		);
	}
}
