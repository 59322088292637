const app = 'https://app.homekeepr.com';
const register = `${app}/register`;

const paths = {
	agents: '/real-estate-agents',
	app,
	blog: '/articles',
	contact: '/contact',
	franchise: '/franchising',
	homeowners: '/homeowners',
	lenders: '/lenders',
	login: app,
	register: {
		agent: `${register}/agent`,
		base: register,
		homeowner: `${register}/homeowner`,
	},
};

export default paths;
