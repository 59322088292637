import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import AppNavigation from 'components/AppNavigation';
import content from 'content/content.json';
import ExternalButton from 'components/shared/ExternalButton';
import paths from '../config/paths';
import React from 'react';

export default class AppHeader extends React.Component {
	render() {
		return (
			<header className="bg-primary">
				<div className="flex items-center justify-between max-w-7xl mx-auto px-4 py-4 lg:py-6">
					<Link to="/">
						<figure className="w-48 lg:w-50">
							<StaticImage
								src="../assets/images/logo-white.svg"
								alt="HomeKeepr Logo"
							/>
						</figure>
					</Link>
					<AppNavigation />
					<section className="hidden lg:block">
						<a
							className="font-semibold hover:underline transition-decoration mr-8 text-base text-white"
							href={paths.app}
							target="_blank"
							rel="noreferrer"
						>
							{content.header.login}
						</a>
						<ExternalButton
							className="font-semibold text-md"
							isExternalLink={true}
							text={content.header.signup}
							url={paths.register.base}
						/>
					</section>
				</div>
			</header>
		);
	}
}
