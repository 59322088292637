import React from 'react';
import { Helmet } from 'react-helmet';
import { hotjar } from 'react-hotjar';
import PropTypes from 'prop-types';

import './layout.css';

if (typeof window !== "undefined") {
	hotjar.initialize('2929528', '6');
}

export default class Metadata extends React.Component {
	render() {
		return (
			<Helmet htmlAttributes={{ lang: 'en' }}>
				<title>{this.props.title}</title>
				<meta name="description" content={this.props.description} />
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link
					rel="preconnect"
					href="https://fonts.gstatic.com"
					crossOrigin
				/>
				<link
					href="https://fonts.googleapis.com/css2?family=Spartan:wght@400;500;600&display=swap"
					rel="stylesheet"
				/>
			</Helmet>
		);
	}
}

Metadata.propTypes = {
	description: PropTypes.string,
	title: PropTypes.string,
};
