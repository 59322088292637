import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'gatsby';

export default class NavigationListItem extends React.Component {
	render() {
		return (
			<li>
				<Link
					target="_blank"
					rel="noreferrer"
					className={this.props.className}
					to={this.props.url}
				>
					{this.props.text}
				</Link>
			</li>
		);
	}
}

NavigationListItem.propTypes = {
	className: PropTypes.string,
	text: PropTypes.string,
	url: PropTypes.string,
};
