import paths from '../config/paths';
import content from 'content/content.json';
import Navigation from 'components/shared/Navigation';
import NavigationCloseIcon from 'assets/svg/times-circle.svg';
import NavigationOpenIcon from 'assets/svg/bars.svg';
import React from 'react';

export default class AppNavigation extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			navigationIsOpen: false,
		};

		this.toggleNavigation = this.toggleNavigation.bind(this);
	}

	toggleNavigation() {
		this.setState((state) => {
			if (state.navigationIsOpen) {
				return { navigationIsOpen: false };
			} else {
				return { navigationIsOpen: true };
			}
		});
	}

	render() {
		const navigationData = [
			{
				className: 'hover:underline transition-decoration',
				text: content.header.navigation.homeowners,
				url: paths.homeowners,
			},
			{
				className: 'hover:underline transition-decoration',
				text: content.header.navigation.lenders,
				url: paths.lenders,
			},
			{
				className: 'hover:underline transition-decoration',
				text: content.header.navigation.agents,
				url: paths.agents,
			},
			{
				className: 'hover:underline transition-decoration',
				text: content.header.navigation.franchisees,
				url: paths.franchise,
			},
			{
				className: 'hover:underline transition-decoration',
				text: content.header.navigation.contact,
				url: paths.contact,
			},
			{
				className: 'hover:underline transition-decoration',
				text: content.header.navigation.blog,
				url: paths.blog,
			},
			{
				className: 'hover:underline transition-decoration lg:hidden',
				text: content.header.login,
				url: paths.login,
			},
			{
				className: 'hover:underline transition-decoration lg:hidden',
				text: content.header.signup,
				url: paths.register.base,
			},
		];

		return (
			<section className="flex justify-center">
				<Navigation
					className="flex flex-col lg:flex-row"
					containerClassName={`
					mobile:bg-primary
					mobile:delay-200
					mobile:ease-in-out
					mobile:fixed
					mobile:h-full
					mobile:inset-x-0
					mobile:px-8
					mobile:transition-top
					${!this.state.navigationIsOpen ? `mobile:-top-full` : `mobile:top-0`}
					mobile:w-full
					mobile:z-10
					`}
					id="nav-menu"
					itemClassName="font-normal inline-block mobile:mt-8 lg:px-4 text-white text-xl lg:text-sm"
					navigationList={navigationData}
				/>
				<button
					aria-controls="nav-menu"
					className="lg:hidden relative z-20"
					onClick={this.toggleNavigation}
					title="Toggle navigation menu"
				>
					{!this.state.navigationIsOpen ? (
						<NavigationOpenIcon className="text-white w-7" />
					) : (
						<NavigationCloseIcon className="text-white w-8" />
					)}
				</button>
			</section>
		);
	}
}
