import paths from '../config/paths';
import { StaticImage } from 'gatsby-plugin-image';
import content from '../content/content.json';
import ExternalButton from 'components/shared/ExternalButton';
import React from 'react';

export default class AppCta extends React.Component {

	render() {
		return (
			<section className="bg-primary">
				<div className="flex flex-row items-end justify-between max-w-7xl mx-auto px-8 pt-4 pb-12">
					<header className="flex-1 flex flex-col items-start md:flex-row md:items-center md:justify-between order-1">
						<h3 className="sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-2xl mb-8 md:mb-0 md:mr-12 order-1 lg:text-xl text-white">
							{content.cta.content}
						</h3>
						<ExternalButton
							className="font-medium order-1 text-center text-sm"
							isExternalLink={true}
							text={content.cta.button}
							url={paths.register.base}
						/>
					</header>
					<figure className="hidden lg:block mr-12 order-0 w-40">
						<StaticImage
							src="../assets/images/cta.png"
							alt="Placeholder Alt Text"
						/>
					</figure>
				</div>
			</section>
		);
	}
}
