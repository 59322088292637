import PropTypes from 'prop-types';
import React from 'react';

export default class ExternalButton extends React.Component {
	render() {
		return (
			<a
				className={`
				${this.props.className} 
				bg-${this.props.backgroundColor} 
				cursor-pointer
				ease-in-out
				font-semibold
				hover:bg-white 
				inline-block
				px-6 
				pt-2
				pb-1.5 
				rounded-3xl 
				text-${this.props.textColor}
				transition-bg 
				`}
				target="_blank"
				rel="noreferrer"
				href={this.props.url}
			>
				{this.props.text}
			</a>
		);
	}
}

ExternalButton.defaultProps = {
	backgroundColor: 'secondary',
	textColor: 'primary',
};

ExternalButton.propTypes = {
	backgroundColor: PropTypes.string,
	className: PropTypes.string,
	text: PropTypes.string.isRequired,
	textColor: PropTypes.string,
	url: PropTypes.string.isRequired,
};
