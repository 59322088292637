import PropTypes from 'prop-types';
import React from 'react';

import AppCta from 'components/AppCta';
import AppFooter from 'components/AppFooter';
import AppHeader from 'components/AppHeader';
import Metadata from 'components/Metadata';

import './layout.css';

export default class Layout extends React.Component {
	render() {
		return (
			<React.StrictMode>
				<div className="min-h-screen flex flex-col">
					<Metadata title={this.props.title} description={this.props.description} />
					<AppHeader />
					<main className={`flex-auto ${this.props.className}`}>
						{this.props.children}
					</main>
					<AppCta />
					<AppFooter />
				</div>
			</React.StrictMode>
		);
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	description: PropTypes.string,
	title: PropTypes.string,
};
